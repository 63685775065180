export const sichouDetail = [
  {
    id: 1,
    defalut: require('@/assets/img/丝绸系列素材/产品模特图/SCCY1.jpg'),
    active: require('@/assets/img/丝绸系列素材/产品模特图/SCCY2.jpg'),
    series: '丝绸系列',
    seriesName: '丝绸唇釉',
    intro: '不沾杯唇泥质地  持久柔雾妆效',
    zhName: '酵色「丝绸唇釉」',
    enName: 'Silky Silk Matte Lipgloss',
    price: '79',
    of: '3.3g',
    imgUrl: [
      { index: 1, url: require('@/assets/img/详情/丝绸素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/1.jpg') },
      { index: 2, url: require('@/assets/img/详情/丝绸素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/2.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/3.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/4.jpg') },
    ],
    color: [
      {
        active: '#b16750',
        name: '#M01 不眠岛屿',
        span1: '土调杏色 干净高级',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/01.jpg')
      },
      {
        active: '#c36356',
        name: '#M02 迷路烟杏',
        span1: '蜜桃奶杏色 素颜轻松驾驭',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/02.jpg')
      },
      {
        active: '#b1464d',
        name: '#M03 心碎豆沙',
        span1: '冷烟粉豆沙 软糯奶糖少女',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/03.jpg')
      },
      {
        active: '#a52310',
        name: '#M04 红釉碎片',
        span1: '热辣血橙色 时髦气场',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/04.jpg')
      },
      {
        active: '#6f3324',
        name: '#M05 土星光环',
        span1: '栗子牛奶 个性酷感',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/05.jpg')
      },
      {
        active: '#d83523',
        name: '#M06 流浪悬日',
        span1: '热辣血橙色 亮眼霓虹',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/06.jpg')
      },
      {
        active: '#da353c',
        name: '#M07 失重春天',
        span1: '草莓甜心 元气少女',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/07.jpg')
      },
      {
        active: '#8a347e',
        name: '#M08 霓虹巴士',
        span1: '特调玫瑰紫薯  不显黑的“口紫”',
        span2: ' ',
        color: require('@/assets/img/详情/丝绸素材/大图/08.jpg')
      },
    ]
  },
  {
    id: 2,
    defalut: require('@/assets/img/丝绸系列素材/产品模特图/SCGG1.jpg'),
    active: require('@/assets/img/丝绸系列素材/产品模特图/SCGG2.jpg'),
    series: '丝绸系列',
    seriesName: '丝绸高光',
    intro: '自然水光感 为亚洲暖黄和冷白皮定制',
    zhName: '酵色「丝绸高光」',
    price: '69',
    of: '3.6-4.5g',
    enName: 'Silky Silk Highlighter',

    imgUrl: [
      { index: 1, url: require('@/assets/img/详情/丝绸素材/丝绸高光/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/04.jpg') },
      { index: 2, url: require('@/assets/img/详情/丝绸素材/丝绸高光/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/02.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/03.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/01.jpg') },
    ],
    color: [
      {
        active: '#e5e0da',
        name: '#06 月落银湖',
        span1: '细密珍珠白',
        span2: '如湖面洒落的粼粼银光',
        color: require('@/assets/img/详情/丝绸素材/丝绸高光/06.jpg')
      },
      {
        active: '#d7c3c3',
        name: '#07 香槟粉梦',
        span1: '月光香槟金',
        span2: '斟满少女的午后美梦',
        color: require('@/assets/img/详情/丝绸素材/丝绸高光/07.jpg')
      },
    ]
  },
  {
    id: 3,
    defalut: require('@/assets/img/丝绸系列素材/产品模特图/SCYP1.jpg'),
    active: require('@/assets/img/丝绸系列素材/产品模特图/SCYP2.jpg'),
    series: '丝绸系列',
    seriesName: '丝绸综合盘',
    intro: '独特又感性的橘绿粉撞色',
    zhName: '酵色「丝绸综合盘」',
    price: '149',
    of: '9g',
    enName: 'Silky Silk Eyeshadow Palette',

    imgUrl: [
      { index: 1, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/04.jpg') },
      { index: 2, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/02.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/03.jpg') },
      { index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸眼盘/01.jpg') },
    ],
    color: [
      {
        active: '#aaab93',
        name: '#09 绿水夏乐',
        span1: '橘绿粉撞色 高闪透亮啫喱',
        span2: '独特又感性 ',
        color: require('@/assets/img/详情/丝绸素材/丝绸眼盘/09.jpg')
      }
    ]
  },
]
